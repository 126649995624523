var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"row L1"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 text-center"},[_vm._m(0),_c('p',[_vm._v("365张绝美照，365个镜头掠影，阅尽锦绣山河")]),_c('div',{staticClass:"btnbox hidden-xs"},[_c('a',{staticClass:"btn",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.download(`https://lg-2ex5nd1c-1255627425.cos.ap-shanghai.myqcloud.com/%E5%AE%89%E8%A3%85%E5%8C%85/LandscapePixelWallpaper%20Setup%201.3.5.exe`)}}},[_vm._v("Windows安装包")]),_c('a',{staticClass:"btn",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.download(`https://lg-2ex5nd1c-1255627425.cos.ap-shanghai.myqcloud.com/%E5%AE%89%E8%A3%85%E5%8C%85/LandscapePixelWallpaper-1.3.5-arm64.dmg`)}}},[_vm._v("MacOS(ARM)安装包")]),_vm._m(1)]),_vm._m(2)]),_vm._m(3)])])]),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v("风光Pixel "),_c('span',[_vm._v("影像日历")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"target":"_blank","href":"https://pan.baidu.com/s/1cdgmRjsr51in9mChZG8qrw&pwd=d5wy"}},[_vm._v("备用下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btnbox visible-xs"},[_c('a',{staticClass:"btn",attrs:{"href":"javascript:void(0);"}},[_vm._v("请前往桌面端下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"screenshot"},[_c('img',{staticClass:"img-responsive",attrs:{"draggable":"false","src":require("@/assets/img/landscapePixelWallpaper/1.gif"),"alt":"","srcset":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row L2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('h1',[_vm._v("举目之处皆为风景")]),_c('p',[_vm._v("全国百位优秀风光摄影师，他们带着相机的足迹遍布世界各地。每年，风光Pixel团队从中精选365张照片，集成在每年限量发售的《风光pixel365日历》中。错过现货发售的小伙伴，现在可以零距离体验到这些极致美图！即刻下载“风光Pxiel”，每天自动换一张绝美壁纸，让世间美景装入电脑，让日常的伏案工作变得更加沉浸。")]),_c('p',[_vm._v("风光Pixel影像日历内置2022 ~ 2025年度壁纸集，我们会在每年更新壁纸库(无须重新下载软件)，喜欢的话，快分享给身边的朋友吧!")])]),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('img',{staticClass:"img-responsive",attrs:{"draggable":"false","src":require("@/assets/img/landscapePixelWallpaper/2.jpg"),"alt":"","srcset":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row L3"},[_c('div',{staticClass:"col-xs-12"},[_c('img',{staticClass:"img-responsive full-img",attrs:{"draggable":"false","src":require("@/assets/img/landscapePixelWallpaper/4.jpg"),"alt":"","srcset":""}})]),_c('div',{staticClass:"container text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 text-center"},[_c('h1',[_vm._v("风光大片如影随形")]),_c('p',[_vm._v("每天自动更换全屏壁纸，除此以外，还有拍摄地、器材、参数、作者、拍摄背景故事等信息，每天打开电脑都能发现全新机位和拍摄思路")]),_c('p',[_vm._v("同时支持Windows、MacOS，让每台电脑都能享受绝美风光")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row Log"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('h1',[_vm._v("版权声明")]),_c('p',[_vm._v("感谢您的关注，风光Pixel影像日历集合了数百位摄影师的精彩作品，每一幅作品的背后，都饱含了摄影师的汗水和泪水，风光Pixel影像日历中所使用的作品均已获得摄影师本人的使用授权，未经摄影师本人同意，任何第三方组织及个人均无权使用，违者必究。")]),_c('h1',[_vm._v("软件说明")]),_c('p',[_vm._v("系统支持Windows 10+ 和 macOS 10.14.4+")]),_c('p',[_vm._v("需要网络连接")]),_c('h1',[_vm._v("更新日志")]),_c('p',[_vm._v("20240919 1.3.5 支持MacOS Sequoia(15.0.0)")]),_c('p',[_vm._v("20231223 1.3.3 修复MacOS Sonoma(14.0.0)中无法更换壁纸的问题。该版本开始，本软件将只支持arm系统。")]),_c('p',[_vm._v("20230104 1.3.2 修复界面问题")]),_c('p',[_vm._v("20221225 1.3.0 增加日历界面")]),_c('p',[_vm._v("20220124 1.2.4 修复mac上无法显示菜单栏图标的问题；修复部分日历说明文案超出边框的问题；")]),_c('p',[_vm._v("20220124 1.2.2 增加启动提示")]),_c('p',[_vm._v("20220124 1.2.0 调整日期算法，适配mac屏幕")]),_c('p',[_vm._v("20220124 1.1.3 1080P显示器下字体放大；修复部分竖图显示错误的问题")]),_c('p',[_vm._v("20220124 1.1.2 调整1080P显示器壁纸效果")]),_c('p',[_vm._v("20220124 1.1.1 调整4K显示器壁纸效果")]),_c('p',[_vm._v("20220124 1.1.0 支持多显示器，支持MAC OS")]),_c('p',[_vm._v("20220122 1.0.1 更新界面")]),_c('p',[_vm._v("20220122 1.0.0 第一版")])])])])])
}]

export { render, staticRenderFns }